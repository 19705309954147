import { Box, Grid, Typography } from '@mui/material';
import { AreaChart } from '../components/areaChart';
import styles from './styles.module.css';
import { ChartCard } from '../components/chartCard';

import { useForm } from 'react-hook-form';
import FiltersVisaoGeral from '../components/filters/FilterVisaoGeral';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import { useContext, useEffect, useState } from 'react';
import api from '../../../../api';
import ErrorsTotals from '../components/ErrorsTotals';
import { getUserToken } from '../../../../lib/auth';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import TipoAchadosStackedBarsChart from '../components/TipoAchadosStackedBarsChart/TipoAchadosStackedBarsChart';

import { LoadingContext } from '../../../../context/LoadingContext';
import CardPendingResults from '../components/CardRealizarBiopsia';
//C:\Users\gabri\OneDrive\Documentos\GitHub\Jordana-CCU-Webapp\src\pages\Panels\colposcopia\components\avaliacaoGeralChart\index.tsx
import { AvaliacaoGeral } from '../components/avaliacaoGeralChart';
import { TableCard } from '../../../../components/TableExamesHistopatologia/TableCard';
import TableHPVColpo from '../components/TableExamesHistopatologia/tableHpvColpo/TableExamesColposcopia';
import TableCitoColpo from '../components/TableExamesHistopatologia/tableCitoColpo/TableExamesColposcopia';

import { get } from 'http';
import CardRealizarBiopsia from '../components/CardRealizarBiopsia';
const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento', value: '' },
  { inputName: 'equipe', value: '' },
  { inputName: 'periodo_inicio', value: '' },
  { inputName: 'periodo_fim', value: '' },
];

export function VisaoGeral() {
  const [listFilter, setListFilter] = useState<any>(initialStateListFilter);
  const [showListFilter, setShowListFilter] = useState<any>([]);
  const [estadosSelected, setEstadosSelected] = useState<any[]>([]);
  const [municipiosSelected, setMunicipiosSelected] = useState<any>([]);
  const [estabelecimentosSelected, setEstabelecimentosSelected] = useState<any>(
    []
  );
  const { control } = useForm();
  const [valueInputPeriod, setValueInputPeriod] = useState<any>();

  const [periodToDisplay, setPeriodToDisplay] = useState('Todos os períodos');

  const [totalTestsPerMonth, setTotalTestsPerMonth] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [countLoading, setCountLoading] = useState(0);
  const [filtersData, setFiltersData] = useState<any>();
  const userToken = getUserToken();
  const isPadrao = userToken.permissao_atual.id === 1;
  const isMunicipal = userToken.permissao_atual.id === 3;
  const isEstadual = userToken.permissao_atual.id === 4;

  const [payload, setPayload] = useState<any>();
  const [total, setTotal] = useState<any>();
    useState<any>([]);

  const [avaliacaoGeralChartData, setavaliacaoGeralChartData] =
    useState<any>([]);

  const [avaliacaoGeralLabels, setavaliacaoGeralLabels] = useState<any>([]);

  const [hpvColpoDataTable, setHpvColpoDataTable] = useState<any>(
    []
  );
  const [citoColpoDataTable, setCitoColpoDataTable] = useState<any>(
    []
  );
  const [tipoAchadosData, setTipoAchadosData] = useState<any>([]);


  const [realizarBiopsiaData, setRealizarBiopsiaData] = useState({
    totalSim: 0,
    totalNao: 0,
    simPercent: 0,
    naoPercent: 0,
  });

  const { setLoading } = useContext(LoadingContext);

  const getTestesPerMonth = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/colposcopia/panel/quantity-month',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
              },
        }
      );

      const data = response.data.data.data;
      const total = response.data.data.total;

      if (!Array.isArray(data) || data.length === 0) {
        setCategories(['NÃO HÁ DADOS']);
        setTotalTestsPerMonth([]);
        setTotal(null);
      } else {
        setCategories(
          data.map((item, index) => {
            if (index === 0 || item.month === 'Jan') {
              return [
                `${String(item.month).toUpperCase()}`,
                `${String(item.year)}`,
              ];
            } else {
              return String(item.month).toUpperCase();
            }
          })
        );

        setTotalTestsPerMonth(data.map((item) => Number(item.count)));
        console.log('total', total);
        console.log('data', data);
        setTotal(total);
        setCountLoading((prev) => prev + 1);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setCategories(['NÃO HÁ DADOS']);
      setTotalTestsPerMonth([]);
      setTotal(null);
    }
  };

  const getAvaliacaoGeralChartData = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/colposcopia/panel/avaliacao-percentagem',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
              },
        }
      );
  
      const data = response.data.data; // Acessa o objeto de dados
  
      if (!data || (data.adequada === 0 && data.inadequada === 0)) {
        setavaliacaoGeralChartData([0, 0]);
        setavaliacaoGeralLabels([]);
      } else {
        setavaliacaoGeralChartData([data.adequada, data.inadequada]);
        setavaliacaoGeralLabels([
          `Adequada (${data.adequada})`,
          `Inadequada (${data.inadequada})`,
        ]);
      }
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      console.error('Error fetching data:', error);
      setavaliacaoGeralChartData([]);
      setavaliacaoGeralLabels([]);
    }
  };

  const getHpvColpoDataTable = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/colposcopia/panel/hpv-colposcopia-table',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
              },
        }
      );

      const data = response.data;

      setHpvColpoDataTable(data);
    } catch (error) {}
  };



  const getCitoColpoDataTable = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/colposcopia/panel/citologia-colposcopia-table',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
              },
        }
      );

      const data = response.data;

      setCitoColpoDataTable(data);
    } catch (error) {}
  };

  const getRealizarBiopsiaData = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/colposcopia/panel/realizar-biopsia',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
              },
        }
      );

      const data = response.data?.data || {};

      const realizarBiopsia = {
        totalSim: data.totalSim || 0,
        totalNao: data.totalNao || 0,
        simPercent: data.simPercent || 0,
        naoPercent: data.naoPercent || 0,
      };

      setRealizarBiopsiaData(realizarBiopsia);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setRealizarBiopsiaData({
        totalSim: 0,
        totalNao: 0,
        simPercent: 0,
        naoPercent: 0,
      });
    }
  };
  interface tipoAchadosData {
    municipio: string;
    achadoNormal: number;
    achadoAnormal: number;
  }
  const getTipoAchadosData = async (cleared, payload) => {
    try {
      const response = await api.get('/dashboard/exames/colposcopia/panel/achados-municipio', {
        params: cleared
          ? {}
          : {
              ...payload.params,
              estados: payload.params.estados,
              municipios: payload.params.municipios,
              estabelecimentos: payload.params.estabelecimentos,
            },
      });
  
      const data = response.data.data;
      setTipoAchadosData(data);
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      console.error('Error fetching tipo achados data:', error);
      setTipoAchadosData([]);
    }
  };



  useEffect(() => {
    setLoading(true);
    if (countLoading >= 9) {
      setLoading(false);
    }
  }, [countLoading]);

  const navigate = useNavigate();

 /* 
 reports: 
 const redirectToReports = () => {
    localStorage.removeItem('result');
    localStorage.removeItem('typeVirus');
    localStorage.removeItem('reasonNotProcedure');
    localStorage.removeItem('collectionOrigin');
    //return
    navigate('/relatorios/colposcopia', {
      state: {
        origin: 'tables',
        table: 'totalValue',
        payload: payload,
        panelClicked: 'totalTests',
      },
    });
  }; */

  useEffect(() => {
    if (isPadrao) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
      setMunicipiosSelected(
        filtersData?.municipios ? [filtersData?.municipios] : []
      );
      setEstabelecimentosSelected(
        filtersData?.estabelecimentos ? [filtersData?.estabelecimentos] : []
      );
    }

    if (isMunicipal) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
      setMunicipiosSelected(
        filtersData?.municipios ? [filtersData?.municipios] : []
      );
    }

    if (isEstadual) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
    }
  }, [filtersData]);

  const getAllCharts = ({ cleared, payload }) => {
    setCountLoading(0);
    localStorage.setItem('coleta_inicio', payload?.periodo_inicio);
    localStorage.setItem('coleta_fim', payload?.periodo_fim);
    setTotalTestsPerMonth([]);
    // setCategories([]);

    setTotal('');
    getAvaliacaoGeralChartData  (cleared, payload);

    getTestesPerMonth(cleared, payload);
    getHpvColpoDataTable(cleared, payload);
    getCitoColpoDataTable(cleared, payload);
    getRealizarBiopsiaData(cleared, payload);
    getTipoAchadosData(cleared, payload);
  };

  useEffect(() => {
    const today = moment().format('YYYY-MM-DD');
    const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

    if (isPadrao) {
      setEstabelecimentosSelected([userToken.estabelecimentoId]);
      getAllCharts({
        cleared: false,
        payload: {
          params: {
            ...payload?.params,

            estabelecimentos: userToken.estabelecimentoId,
          },
        },
      });
    } else {
      getAllCharts({
        cleared: false,
        payload: {
          params: {
            ...payload?.params,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    setTotal(totalTestsPerMonth.reduce((prev, curr) => prev + curr, 0));
  }, [totalTestsPerMonth]);

  /* useEffect(() => {
    if (!userToken.permissoes.includes('VIEW_DASHBOARD_COLPOSCOPIA')) {
      navigate('/home');
    }
  }, []); */

  return (
    <Box mt="31px">
      <Box sx={{ marginBottom: '42px' }}>
        <FiltersVisaoGeral
          setPeriodToDisplay={setPeriodToDisplay}
          payload={payload}
          valueInputPeriod={valueInputPeriod}
          setValueInputPeriod={setValueInputPeriod}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          setEstadosSelected={setEstadosSelected}
          setMunicipiosSelected={setMunicipiosSelected}
          municipiosSelected={municipiosSelected}
          estadosSelected={estadosSelected}
          estabelecimentosSelected={estabelecimentosSelected}
          control={control}
          setShowListFilter={setShowListFilter}
          setPayload={setPayload}
          getAllCharts={getAllCharts}
          setListFilter={setListFilter}
          initialStateListFilter={initialStateListFilter}
          listFilter={listFilter}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="2px">
        <Typography variant="h5" fontWeight="bold" fontSize="24px">
          Testes de Colposcopia - Visão Geral
        </Typography>
        <Typography variant="h6" fontWeight="bold" fontSize="20px">
          {periodToDisplay}
        </Typography>

        <DisplayFilter style={{ marginTop: '17px', marginBottom: '37px' }}>
          <p style={{ margin: 0, width: '138px' }}>Filtros aplicados:</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {showListFilter.map((item) => (
              <>
                {item.value ? (
                  <>
                    <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                  </>
                ) : undefined}
              </>
            ))}
          </div>
        </DisplayFilter>

        {/* <div className={styles.filtersInfo}>
          Filtros aplicados: Estado: Todos - Município: Todos - Estabelecimento:
          Todos - Equipe: Todas - Período: Últimos 12 meses.
        </div> */}
      </Box>

      <div className={styles.testsDoneCard}>
        <div className={styles.testsDoneCardTitle}>
          <Typography variant="h5" fontWeight="bold">
            Exames de Colposcopia <br />
            Quantidade de Exames Realizados
          </Typography>
          <p style={{ margin: 0, fontSize: '12px', color: '#9E9E9E' }}>
            {valueInputPeriod === 'Todos' || !valueInputPeriod
              ? 'Todos os períodos'
              : valueInputPeriod}
          </p>
          <Typography variant="h5" fontWeight="bold">
            {total ? total?.toLocaleString('pt-BR') : ''}
          </Typography>
        </div>

        <AreaChart
          data={totalTestsPerMonth}
          categories={categories}
          payload={null}
        />

        <p
          style={{
            marginBottom: '16px',
            marginRight: '16px',
            bottom: '0',
            right: '0',
            position: 'absolute',
            fontSize: '10px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          Fonte dos Dados: JORDANA
        </p>
      </div>

      <Grid container spacing={2}>


        <Grid item xs={12} md={6}>
          <ChartCard
            valueInputPeriod={valueInputPeriod}
            title="Exames de Colposcopia"
            subtitle="Avaliação Geral da Colposcopia"
          >
            <AvaliacaoGeral
              data={avaliacaoGeralChartData}
              labels={avaliacaoGeralLabels}
              colors={['#775DD0', '#CC64DD']}
            />

          </ChartCard>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          gap="16px"
          display="flex"
          flexDirection="column"
        >
          <CardRealizarBiopsia
          title='Exames de Colposcopia (Realizar Biópsia - Sim)'
          period={valueInputPeriod === 'Todos' || !valueInputPeriod
            ? 'Todos os períodos'
            : valueInputPeriod}
            color='#ED6C02'
            number={realizarBiopsiaData.totalSim}
            percentage={realizarBiopsiaData.simPercent}
          />
          <CardRealizarBiopsia
          title='Exames de Colposcopia (Realizar Biópsia - Não)'
          period={valueInputPeriod === 'Todos' || !valueInputPeriod
            ? 'Todos os períodos'
            : valueInputPeriod}
            color='#4CAF50'
            number={realizarBiopsiaData.totalNao}
            percentage={realizarBiopsiaData.naoPercent}
          />
        </Grid>

      {/* Adicionar aqui card + tableExamesColposcopia */}
      
      <Grid item xs={12}>
          <TableCard
            title={`Exames de Colposcopia)`}
            subtitle={'Mulheres com HPV 16 ou 18 x Colposcopias Realizadas'}
            fullWidth
            valueInputPeriod={valueInputPeriod}
          >
            <TableHPVColpo
                data={hpvColpoDataTable.data}
                   totals={hpvColpoDataTable.totals}
                    />
            <p
              style={{
                marginBottom: '16px',
                marginRight: '16px',
                bottom: '0',
                right: '0',
                position: 'absolute',
                fontSize: '10px',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              Fonte dos Dados: JORDANA
            </p>
          </TableCard>
        </Grid>

        <Grid item xs={12}>
          <TableCard
            title={`Exames de Colposcopia)`}
            subtitle={'Mulheres com Citologia Insatisfatória ou Com Alterações Epteliais x Colposcopias Realizadas'}
            fullWidth
            valueInputPeriod={valueInputPeriod}
          >
            <TableCitoColpo
                data={citoColpoDataTable.data}
                   totals={citoColpoDataTable.totals}
                    />
            <p
              style={{
                marginBottom: '16px',
                marginRight: '16px',
                bottom: '0',
                right: '0',
                position: 'absolute',
                fontSize: '10px',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              Fonte dos Dados: JORDANA
            </p>
          </TableCard>
        </Grid>

        {['SuperAdmin', 'Admin Estadual'].includes(
          userToken.permissao_atual.nome
        ) && (
          <>

          </>
        )}
      </Grid>

      {['SuperAdmin', 'Admin Estadual'].includes(
        userToken.permissao_atual.nome
      ) && (
        <p
          style={{
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.38)',
            width: '100%',
            textAlign: 'right',
            marginTop: '24px',
            marginBottom: '40px',
          }}
        >
          * O quantitativo é atualizado por automatização do sistema com o GAL
        </p>
      )}
    </Box>
  );
}

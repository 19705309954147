import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import moment from 'moment';
import Chart from 'react-apexcharts';
import { Box, Grid, Typography } from '@mui/material';

import api from '../../../../api';
import { getUserToken } from '../../../../lib/auth';
import { LoadingContext } from '../../../../context/LoadingContext';

import styles from './styles.module.css';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';

import { BarChart } from '../../../../components/Charts/barChart';

import ItemGraphic from '../components/RealizationProcedurePosNotRealized/ItemGraphic';
import { PieChart2 } from '../components/pieChart2';
import { TableResults } from '../components/tableResults';
import FiltersVisaoGeral from '../components/filters/FilterVisaoGeral';
import EmptyData from '../../../../components/Charts/emptyData';
import { AreaChart } from '../components/areaChart';

import { DataSource } from '../../hpv/components/dataSource';
import { ChartCard } from '../../hpv/components/chartCard';

interface FilterItem {
  inputName: string;
  value: string;
}

interface ExamResultsData {
  negativo: number[];
  alteracao: number[];
  insatisfatorio: number[];
}

// interface QuantityMonthData {
//   year: number;
//   month: string;
//   month_n: number;
//   count: string;
// }

interface AtypicalCellsData {
  escamosa: { count_neoplasica: number; count_lesao: number };
  glandular: { count_neoplasica: number; count_lesao: number };
  indefinida: { count_neoplasica: number; count_lesao: number };
}

interface GlandularAdenomaData {
  label: string;
  count: number;
}

interface SquamousCellAtypiaData {
  tipo: string;
  count: number;
}

interface DataRow {
  label: string;
  hpv_outros_count: number;
  citologia_realizar: number;
  citologia_count: number;
}

interface Payload {
  params: {
    estados?: string;
    periodo_inicio?: string;
    periodo_fim?: string;
    tipo?: string;
    page?: number;
    size?: number;
    [key: string]: any;
  };
}

const initialStateListFilter: FilterItem[] = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento', value: '' },
  { inputName: 'periodo', value: '' },
];

export function VisaoGeral() {
  const [listFilter, setListFilter] = useState<FilterItem[]>(
    initialStateListFilter
  );
  const [showListFilter, setShowListFilter] = useState<FilterItem[]>([]);
  const [estadosSelected, setEstadosSelected] = useState<any[]>([]);
  const [municipiosSelected, setMunicipiosSelected] = useState<any>([]);
  const [estabelecimentosSelected, setEstabelecimentosSelected] = useState<any>(
    []
  );
  const [totalTestsPerMonth, setTotalTestsPerMonth] = useState<number[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [payload, setPayload] = useState<Payload>({
    params: {
      page: 1,
      size: 25,
      tipo: 'Reflexo',
    },
  });
  const [countLoading, setCountLoading] = useState(0);
  const [tableData, setTableData] = useState<DataRow[]>([]);
  const [totalTableItems, setTotalTableItems] = useState(0);
  const [period, setPeriod] = useState<{
    periodo_inicio?: string;
    periodo_fim?: string;
  }>({});
  const [periodToDisplay, setPeriodToDisplay] = useState('Todos os períodos');
  const [valueInputPeriod, setValueInputPeriod] = useState<any>();

  const [defaultState, setDefaultState] = useState('PE');

  const [total, setTotal] = useState(0);
  const [disableGraphicsOnHover, setDisableGraphicsOnHover] = useState(0);
  const [examResultsData, setExamResultsData] = useState<ExamResultsData>({
    negativo: [],
    alteracao: [],
    insatisfatorio: [],
  });
  const [examResultsCategories, setExamResultsCategories] = useState<string[]>(
    []
  );

  const [isLoading, setIsLoading] = useState(true);
  const [atypicalCells, setAtypicalCells] = useState<AtypicalCellsData>({
    escamosa: { count_neoplasica: 0, count_lesao: 0 },
    glandular: { count_neoplasica: 0, count_lesao: 0 },
    indefinida: { count_neoplasica: 0, count_lesao: 0 },
  });

  const [glandularAdenoma, setGlandularAdenoma] = useState<
    GlandularAdenomaData[]
  >([]);
  const [squamousCellAtypia, setSquamousCellAtypia] = useState<
    SquamousCellAtypiaData[]
  >([]);
  const [filtersData, setFiltersData] = useState<any>();

  const refRealized = useRef<HTMLSpanElement>(null);
  const refNotRealized = useRef<HTMLSpanElement>(null);
  const { control } = useForm();
  const userToken = getUserToken();

  const navigate = useNavigate();

  const isPadrao = userToken.permissao_atual.id === 1;
  const isMunicipal = userToken.permissao_atual.id === 3;
  const isEstadual = userToken.permissao_atual.id === 4;

  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (isPadrao) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
      setMunicipiosSelected(
        filtersData?.municipios ? [filtersData?.municipios] : []
      );
      setEstabelecimentosSelected(
        filtersData?.estabelecimentos ? [filtersData?.estabelecimentos] : []
      );
    }

    if (isMunicipal) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
      setMunicipiosSelected(
        filtersData?.municipios ? [filtersData?.municipios] : []
      );
    }

    if (isEstadual) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
    }
  }, [filtersData]);

  useEffect(() => {
    setTotal(totalTestsPerMonth.reduce((prev, curr) => prev + curr, 0));
  }, [totalTestsPerMonth]);

  useEffect(() => {
    if (isPadrao) {
      setEstabelecimentosSelected([userToken.estabelecimentoId]);
      getAllCharts({
        cleared: false,
        payload: {
          params: {
            ...payload.params,
            estabelecimentos: userToken.estabelecimentoId,
          },
        },
      });
    } else {
      getAllCharts({
        cleared: false,
        payload: {
          params: {
            ...payload.params,
          },
        },
      });
    }
  }, []);

  const handleMouseMove = (event: React.MouseEvent) => {
    const element = document.elementFromPoint(event.clientX, event.clientY);
    setDisableGraphicsOnHover(
      element === refRealized.current
        ? 1
        : element === refNotRealized.current
        ? 2
        : 0
    );
  };

  const calculateSum = (data: number[]) =>
    data.reduce((sum, value) => sum + value, 0);

  const getTestesPerMonth = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/quantity-month',
        {
          params: { ...payload.params, tipo: 'Reflexo' },
        }
      );

      if (response.data.data.length === 0) {
        setCategories(['NÃO HÁ DADOS']);
      } else {
        setCategories(
          response.data?.data.map((item, index) => {
            if (index === 0 || item?.month === 'Jan') {
              return [
                `${String(item?.month)?.toUpperCase()}`,
                `${String(item?.year)}`,
              ];
            } else {
              return String(item?.month).toUpperCase();
            }
          })
        );
      }

      setTotalTestsPerMonth(
        response.data?.data.map((item) => Number(item?.count))
      );

      setFiltersData(response.data.filters);
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      console.error('Error fetching tests per month:', error);
    }
  };
  const getExamResults = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/resultados',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );
      const data = response.data?.data || [];

      const processedData: ExamResultsData = {
        negativo: [],
        alteracao: [],
        insatisfatorio: [],
      };
      const categories: string[] = [];

      data.forEach((item: any) => {
        processedData.negativo.push(parseInt(item.count_negativo));
        processedData.alteracao.push(parseInt(item.count_alteracao));
        processedData.insatisfatorio.push(parseInt(item.count_insatisfatorio));
        categories.push(`${item.month}/${item.year.slice(-2)}`);
      });

      setExamResultsData(processedData);
      setExamResultsCategories(categories);
    } catch (error) {
      console.error('Error fetching exam results:', error);
    }
  };

  const getAtypicalCells = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/celulas-atipicas',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );
      if (response.data?.data) {
        setAtypicalCells({
          escamosa: {
            count_neoplasica: parseInt(
              response.data.data.escamosa.count_neoplasica ?? 0
            ),
            count_lesao: parseInt(response.data.data.escamosa.count_lesao ?? 0),
          },
          glandular: {
            count_neoplasica: parseInt(
              response.data.data.glandular.count_neoplasica ?? 0
            ),
            count_lesao: parseInt(
              response.data.data.glandular.count_lesao ?? 0
            ),
          },
          indefinida: {
            count_neoplasica: parseInt(
              response.data.data.indefinida.count_neoplasica ?? 0
            ),
            count_lesao: parseInt(
              response.data.data.indefinida.count_lesao ?? 0
            ),
          },
        });
      }
    } catch (error) {
      console.error('Error fetching atypical cells data:', error);
      setAtypicalCells({
        escamosa: { count_neoplasica: 0, count_lesao: 0 },
        glandular: { count_neoplasica: 0, count_lesao: 0 },
        indefinida: { count_neoplasica: 0, count_lesao: 0 },
      });
    }
  };

  const getGlandularAdenoma = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/celulas-glandulares-adeno',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );
      const data = response.data?.data || [];

      const cleanLabel = (label: string): string[] => {
        const cleanedLabel = label.replace(/Adenocarcinoma\s*/g, '').trim();

        if (cleanedLabel.toLowerCase().includes('invasor')) {
          if (cleanedLabel.toLowerCase().includes('em outras especificações')) {
            return ['Invasor', 'em outras', 'especificações'];
          } else {
            const parts = cleanedLabel.split(/\s+/);
            const invasorIndex = parts.findIndex(
              (part) => part.toLowerCase() === 'invasor'
            );
            if (invasorIndex !== -1) {
              parts[invasorIndex] = 'Invasor';
              return [
                parts.slice(0, invasorIndex + 1).join(' '),
                ...parts.slice(invasorIndex + 1),
              ];
            }
          }
        } else if (cleanedLabel.toLowerCase().includes('in situ')) {
          return ['In situ'];
        }

        return [cleanedLabel];
      };

      setGlandularAdenoma(
        data.map((item) => ({
          label: cleanLabel(item.label),
          count: parseInt(item.count),
        }))
      );
    } catch (error) {
      console.error('Error fetching glandular adenoma data:', error);
    }
  };

  const getSquamousCellAtypia = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/celulas-escamosas',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );
      const data = response.data?.data || [];
      setSquamousCellAtypia(
        data.map((item) => ({
          tipo: item.tipo,
          count: parseInt(item.count),
        }))
      );
    } catch (error) {
      console.error('Error fetching squamous cell atypia data:', error);
    }
  };

  const fetchTableData = async (currentPayload: Payload) => {
    try {
      let params = {
        ...currentPayload.params,
        page: currentPayload.params.page,
        size: currentPayload.params.size,
      };

      if (!estadosSelected.length) {
        params = { ...params, estados: JSON.stringify([defaultState]) };
      }

      const response = await api.get(
        '/dashboard/exames/citologia/panel/quantity-citologia-outros',
        { params }
      );
      setTableData(response.data.data.data);
      setTotalTableItems(response.data.data.totalItems);
    } catch (error) {
      console.error('Error fetching table data:', error);
    }
  };

  useEffect(() => {
    if (
      !userToken ||
      !userToken.permissoes ||
      !userToken.permissoes.includes('VIEW_DASHBOARD')
    ) {
      navigate('/home');
    }
  }, [userToken, navigate]);

  useEffect(() => {
    if (payload && payload.params) {
      fetchTableData(payload);
    }
  }, [payload]);

  const handleTablePageChange = (newPage: number) => {
    setPayload((prevPayload) => {
      const newPayload = {
        ...prevPayload,
        params: { ...prevPayload.params, page: newPage + 1 },
      };
      return newPayload;
    });
  };

  useEffect(() => {
    setLoading(true);
    if (countLoading >= 9) {
      setLoading(false);
    }
  }, [countLoading]);

  const redirectToReports = (origin, table, additionalParams = {}) => {
    const currentDate = moment().format('YYYY-MM-DDTHH:mm:ss');

    const baseFilters = {
      estado: estadosSelected.join(','),
      municipioId: municipiosSelected.join(','),
      estabelecimentoId: estabelecimentosSelected.join(','),
      tipo: 'Reflexo',
      search: '',
      size: 25,
      page: 1,
      periodo_fim: period.periodo_fim || currentDate,
      periodo_inicio: period.periodo_inicio || '2023-09-01',
    };

    const currentFilters = {
      ...baseFilters,
      ...payload.params,
      ...additionalParams,
    };

    navigate('/relatorios/citologia-reflexo', {
      state: {
        origin,
        table,
        payload: {
          params: currentFilters,
        },
      },
    });
  };

  const handleChartClick = (event, chartContext, config) => {
    const { seriesIndex, dataPointIndex } = config;
    const clickedSeries = [
      'Com Alterações Epiteliais',
      'Negativo',
      'Insatisfatório',
    ][seriesIndex];

    const resultadoMapping = {
      'Com Alterações Epiteliais': 'Alterações Epiteliais',
      Negativo: 'Negativo',
      Insatisfatório: 'Insatisfatório',
    };

    redirectToReports('charts', 'resultadoExame', {
      resultado: resultadoMapping[clickedSeries],
      month: examResultsCategories[dataPointIndex],
    });
  };

  const handleAtypicalCellsClick = (cellType) => {
    redirectToReports('charts', 'celulasAtipicas', {
      celula_atipica: cellType,
    });
  };

  const handleGlandularAdenomaClick = (dataPointIndex: number) => {
    const clickedItem = glandularAdenoma[dataPointIndex];
    redirectToReports('charts', 'celulasGlandulares', {
      celula_glandular: clickedItem.label,
    });
  };

  const handleSquamousCellAtypiaClick = (event, chartContext, config) => {
    if (
      config &&
      typeof config.dataPointIndex === 'number' &&
      squamousCellAtypia[config.dataPointIndex]
    ) {
      const clickedItem = squamousCellAtypia[config.dataPointIndex];
      redirectToReports('charts', 'celulasEscamosas', {
        celula_escamosa: clickedItem.tipo,
      });
    } else {
      console.error('Dados inválidos para redirecionamento', {
        event,
        chartContext,
        config,
      });
    }
  };

  const handleTableRowsPerPageChange = (newRowsPerPage: number) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      params: { ...prevPayload.params, size: newRowsPerPage, page: 1 },
    }));
  };

  const redirectToTotalReports = () => {
    redirectToReports('tables', 'totalValue');
  };

  const getAllCharts = async ({ cleared, payload: newPayload }) => {
    if (!newPayload) return;
    setIsLoading(true);
    setLoading(true);

    try {
      const updatedPayload = cleared
        ? newPayload
        : {
            ...newPayload,
            params: {
              ...newPayload.params,
              estados: JSON.stringify(estadosSelected),
              municipios: municipiosSelected.join(', '),
              estabelecimentos: estabelecimentosSelected.join(', '),
            },
          };
      setPayload(updatedPayload);

      const commonParams = {
        ...updatedPayload.params,
        tipo: 'Reflexo',
      };

      await Promise.all([
        getTestesPerMonth(cleared, { params: commonParams }),
        getExamResults(cleared, { params: commonParams }),
        getAtypicalCells(cleared, { params: commonParams }),
        getGlandularAdenoma(cleared, { params: commonParams }),
        getSquamousCellAtypia(cleared, { params: commonParams }),
        fetchTableData({ params: commonParams }),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };

  const emptyDataChartExamResult = examResultsCategories.length === 0;

  const emptyDataChartCelulaAtipicaIndeterminado =
    Object.keys(atypicalCells).filter((valueName) => {
      return (
        atypicalCells[valueName].count_neoplasica > 0 ||
        atypicalCells[valueName].count_lesao > 0
      );
    }).length === 0;

  return (
    <Box mt="31px">
      <Box sx={{ marginBottom: '42px' }}>
        <FiltersVisaoGeral
          setPeriodToDisplay={setPeriodToDisplay}
          valueInputPeriod={valueInputPeriod}
          setValueInputPeriod={setValueInputPeriod}
          payload={payload}
          setPayload={setPayload}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          setEstadosSelected={setEstadosSelected}
          setMunicipiosSelected={setMunicipiosSelected}
          municipiosSelected={municipiosSelected}
          estadosSelected={estadosSelected}
          estabelecimentosSelected={estabelecimentosSelected}
          control={control}
          setShowListFilter={setShowListFilter}
          getAllCharts={getAllCharts}
          setListFilter={setListFilter}
          initialStateListFilter={initialStateListFilter}
          listFilter={listFilter}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="2px">
        <Typography variant="h5" fontWeight="bold" fontSize="24px">
          Testes de Citologia - Reflexo - Visão Geral
        </Typography>
        <Typography variant="h6" fontWeight="bold" fontSize="20px">
          {periodToDisplay}
        </Typography>

        <DisplayFilter style={{ marginTop: '17px', marginBottom: '37px' }}>
          <p style={{ margin: 0, width: '138px' }}>Filtros aplicados:</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {showListFilter.map(
              (item, index) =>
                item.value && (
                  <ItemsDisplayFilter key={index}>
                    {item.value}
                  </ItemsDisplayFilter>
                )
            )}
          </div>
        </DisplayFilter>
      </Box>

      <div className={styles.testsDoneCard}>
        <div className={styles.testsDoneCardTitle}>
          <Typography variant="h5" fontWeight="bold">
            Exames de Citologia - Reflexo
            <br />
            Total de Exames Realizados
          </Typography>

          <p style={{ margin: 0, fontSize: '12px', color: '#9E9E9E' }}>
            {valueInputPeriod === 'Todos' || !valueInputPeriod
              ? 'Todos os períodos'
              : valueInputPeriod}
          </p>

          <Typography
            variant="h5"
            fontWeight="bold"
            style={{ cursor: 'pointer' }}
            onClick={redirectToTotalReports}
          >
            {total?.toLocaleString('pt-BR') || ''}
          </Typography>
        </div>
        <AreaChart
          data={totalTestsPerMonth}
          categories={categories}
          payload={payload}
          total={total}
          // onClick={redirectToTotalReports}
        />
        <DataSource />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Resultado do Exame"
            valueInputPeriod={valueInputPeriod}
          >
            <div className="chartOnCenter" style={{ position: 'relative' }}>
              {emptyDataChartExamResult ? (
                <div style={{ position: 'absolute', left: '40%', zIndex: '1' }}>
                  <EmptyData />
                </div>
              ) : undefined}
              <Chart
                options={{
                  chart: {
                    height: 350,
                    type: 'line',
                    zoom: { enabled: false },
                    toolbar: { show: false },
                    events: {
                      click: handleChartClick,
                    },
                  },
                  stroke: { width: 3, curve: 'straight', dashArray: [] },
                  dataLabels: { enabled: false },
                  markers: { size: 0 },
                  xaxis: {
                    categories: examResultsCategories,
                  },
                  colors: ['#f44336', '#238884', '#9e9e9e'],
                  tooltip: {
                    //   enabled: false,
                    y: { formatter: (val: number) => `${val} exames` },
                  },
                  legend: {
                    show: true,
                    position: 'top',
                    itemMargin: {
                      horizontal: 25,
                      vertical: 5,
                    },
                    formatter: (seriesName, opts) => {
                      const dataSum = calculateSum(
                        opts.w.globals.series[opts.seriesIndex]
                      );
                      return `${seriesName} (${dataSum})`;
                    },
                  },
                }}
                series={[
                  {
                    name: 'Com Alterações Epiteliais',
                    data: examResultsData.alteracao,
                  },
                  { name: 'Negativo', data: examResultsData.negativo },
                  {
                    name: 'Insatisfatório',
                    data: examResultsData.insatisfatorio,
                  },
                ]}
                type="line"
                height={350}
              />
            </div>
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Células Atípicas de Significado Indeterminado"
            valueInputPeriod={valueInputPeriod}
          >
            {emptyDataChartCelulaAtipicaIndeterminado ? (
              <EmptyData />
            ) : (
              <>
                <div className="boxData" onMouseMove={handleMouseMove}>
                  <div
                    className="boxDatalegends"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      marginTop: '60px',
                      marginBottom: '16px',
                      fontSize: '14px',
                    }}
                  >
                    <div
                      className="legendItem"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <div
                        className="boxDatalegendscolorizedLegend"
                        style={{
                          backgroundColor: '#7b61ff',
                          width: '12px',
                          height: '12px',
                          // borderRadius: '50%', // define como circulo
                          borderRadius: '2px',
                          display: 'inline-block',
                          marginRight: '8px',
                          flexShrink: 0,
                          marginTop: '4px',
                        }}
                      />
                      <span ref={refRealized} style={{ lineHeight: '1.2' }}>
                        Possívelmente não neoplásticas (ASC-US)
                      </span>
                    </div>
                    <div
                      className="legendItem"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <div
                        className="boxDatalegendscolorizedLegend"
                        style={{
                          backgroundColor: '#ed6c02',
                          width: '12px',
                          height: '12px',
                          // borderRadius: '50%',
                          borderRadius: '2px',
                          display: 'inline-block',
                          marginRight: '8px',
                          flexShrink: 0,
                          marginTop: '4px',
                        }}
                      />
                      <span ref={refNotRealized} style={{ lineHeight: '1.2' }}>
                        Não se pode afastar lesão de alto grau (ASC-H)
                      </span>
                    </div>
                  </div>
                  <div
                    className="boxDatacontent"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '220px',
                      width: '100%',
                      padding: '10px 20px',
                      boxSizing: 'border-box',
                    }}
                  >
                    {Object.entries(atypicalCells).map(
                      ([key, value], index) => (
                        <ItemGraphic
                          key={index}
                          value1={value.count_neoplasica}
                          value2={value.count_lesao}
                          label={
                            key === 'indefinida'
                              ? 'De origem indefinida'
                              : key.charAt(0).toUpperCase() + key.slice(1)
                          }
                          disableGraphicsOnHover={disableGraphicsOnHover}
                          onClick={() => handleAtypicalCellsClick(key)}
                        />
                      )
                    )}
                  </div>
                </div>
              </>
            )}
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Atipias em Células Glandulares Adenocarcinoma"
            valueInputPeriod={valueInputPeriod}
          >
            <BarChart
              data={glandularAdenoma.map((item) => item.count)}
              categories={glandularAdenoma.map((item) => item.label)}
              colors={['#2dc4cc']}
              onClick={(event, chartContext, config) => {
                if (config && typeof config.dataPointIndex === 'number') {
                  handleGlandularAdenomaClick(config.dataPointIndex);
                }
              }}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Atipias em Células Escamosas"
            valueInputPeriod={valueInputPeriod}
          >
            <PieChart2
              data={squamousCellAtypia.map((item) => item.count)}
              height={284}
              labels={squamousCellAtypia.map((item) => item.tipo)}
              colors={['#bdbdbd', '#2196f3', '#ffb547', '#f44336']}
              legendWidth={500}
              customLegend={(seriesName, opts) => {
                const index = opts.seriesIndex;
                const count = squamousCellAtypia[index]?.count || 0;
                return `${seriesName} (${count})`;
              }}
              onClick={handleSquamousCellAtypiaClick}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12}>
          <Box mt="2px">
            <TableResults
              data={tableData}
              valueInputPeriod={valueInputPeriod}
              totalItems={totalTableItems}
              onPageChange={handleTablePageChange}
              onRowsPerPageChange={handleTableRowsPerPageChange}
              page={(payload.params.page || 1) - 1}
              rowsPerPage={payload.params.size || 25}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
